:root {
  /* --color-background-white: #ffffff;
  --color-black: #000000;
  --color-white: #ffffff;
  --color-pink: #efa6a6;
  --color-blue: #01004e;
  --color-yellow: #e5af0a;
  --color-green: #cad336;
  --color-grey: #323232;
  --color-grey-light: #5d5d5d;
  --color-grey-thin: #f4f4f4;
  --color-mauve: #9f93a3;
  --color-bordeaux: #a84558;

  --turquoise_light: #1c6781; */

  --color_main: linear-gradient(200deg, rgba(62,97,124,1) 0%, rgba(90,105,175,1) 50%, rgba(39,53,130,1) 100%);

  --red: #e92857;
  --red_light: #e6234d;

  --header-red: #ed3561;
}

* {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  margin: 0;
  padding: 0;
}

/* COULEURS */
.rouge {
  color: #ed3561;
}
.bg-blue{
  background: var(--color_main);
}
.bg-rouge-button {
  background-color: #ed3561;
  color: #fff;
  border-radius: 7px;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
}

.bleu {
  color: #3e617c;
}

/* POLICES */
.roboto {
  font-family: "Roboto", sans-serif;
}

.oswald {
  font-family: "Oswald", sans-serif;
}

/* HEADER */
.bandeau {
  background-color: #ed3561;
  color: #fff;
  /*
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 0px 35px 0px;
  */
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  padding: calc(8px + 0.2vw) 10px;
  margin: 0;
}

.bandeau .savethedate {
  color: #ed3561;
  font-weight: bold;
  font-size: 1.1rem;
  text-transform: uppercase;
  background: #fff;
  padding: 7px 19px;
  border-radius: 3px;
}

.header {
  height: 85px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
}

.header a {
  text-decoration: none;
  color: var(--color_main);
  font-size: 1.4rem;
}

.header .active {
  padding-bottom: 18px;
  margin-top: 28px;
  color: #ed3561;
  border-bottom: solid 10px #ed3561;
  letter-spacing: 3px;
}

.header-date {
  font-size: 2.1rem;
  color: #ed3561;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #fff;
  padding: 12px 30px;
  border: 1px solid #fff;
  border-radius: 30px;
}

/* PAGE */
.page {
  width: 100%;
  position: relative;
  overflow-x: hidden;
  padding: 0;
  padding-top: 100px;
  padding-bottom: 35px;
  margin: 0;
  background: var(--color_main);
}

.page-offres {
  width: 100%;
  position: relative;
  overflow-x: hidden;
  padding: 0;
  padding-top: 100px;
  padding-bottom: 60px;
  margin: 0;
  background: var(--color_main);
  border-bottom: solid 10px #ed3561;
}

.page-partenaires {
  width: 100%;
  position: relative;
  overflow-x: hidden;
  padding: 0;
  padding: 75px 0;
  margin: 0;
  background: var(--color_main);
  border-bottom: solid 10px #ed3561;
}

.title-partenaires {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-size: 28px;
  text-transform: uppercase;
  color: #1758a0;
  line-height: 32px;
  margin-top: 30px;
  margin-bottom: 25px;
}

.partenaires-titre {
  font-size: 1.4rem;
  font-weight: 800;
  margin-bottom: 35px;
  text-transform: uppercase;
  text-align: center;
  padding: 25px 25px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  color: var(--color_main) !important;
}

.infos-pratiques {
  width: 100%;
  /* position: relative; */
  overflow-x: hidden;
  padding: 80px 0px;
  margin: 0;
  background: #ed3561;
  text-align: center;
}

.infos-pratiques h2 {
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
}

.infos-pratiques h3 {
  color: #fff;
  font-weight: 700;
  font-size: 1.4rem;
  padding-top: 25px;
}

.infos-pratiques p {
  color: #fff;
  font-size: 0.9rem;
}

.infos-pratiques a {
  color: #fff;
  font-size: 0.95rem;
  font-weight: 900;
  text-decoration: none;
}

.container-video {
  padding-top: 100px;
}

.video {
  width: 100%;
  margin: 0 0 25px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video video {
  width: 100%;
  max-width: 1100px;
  outline: none !important;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.35);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.35);
}

.button-programme-home {
  background-color: #ed3561;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
}

.button-programme-home:hover {
  background-color: #fff;
  border: none;
  color: #ed3561;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
}

.title-hero-forum {
  font-size: 2.3rem;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
  text-align: end;
}
.subtitle-home{
  width: 100%;
  display: flex;
  flex-direction: column;
}
.title-hero-main {
  font-size: 6.2rem;
  /* font-weight: bold; */
  color: #fff;
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
  letter-spacing: 7px;
  font-weight: 200;
  text-align: end;
}

.title-hero-main-bold-left {
  font-size: 6rem;
  /* font-weight: bold; */
  color: #fff;
  margin: 0 auto 0.5rem 0;
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
  font-weight: 900;
}

.title-hero-main-bold-right {
  font-size: 6rem;
  /* font-weight: bold; */
  color: #fff;
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
  font-weight: 900;
  text-align: end;
}
.forum-title{
  font-size: 2rem;
  font-weight: 700;
}
.title-actualites-home {
  font-size: 2.2rem;
  font-weight: bold;
  color: #ed3561;
  text-transform: uppercase;
  padding: 35px 0px;
}

.title-contact-home {
  font-size: 2.2rem;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  padding: 35px 0px;
}

.programme .card {
  --bs-card-bg: none !important;
  color: #fff !important;
  border: none !important;
}

.button-partenaires {
  background-color: #ed3561;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
}

.button-partenaires:hover {
  background-color: var(--color_main);
  color: #fff;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
}

.button-partenaires-social {
  background-color: #ed3561;
  border: none;
  color: white;
  /* padding: 15px 32px; */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
}

.button-partenaires-social:hover {
  background-color: var(--color_main);
  color: #fff;
  border: none;
  /* padding: 15px 32px; */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
}

.button-contact {
  border: solid 1px #ed3561;
  background-color: #ed3561;
  color: #fff;
  padding: 6px 20px 8px;
  text-align: center;
  display: inline-block;
  font-size: 18px;
  text-transform: uppercase;
}

.button-contact:hover {
  border: solid 1px #fff;
  background-color: #fff;
  color: #ed3561;
  padding: 6px 20px 8px;
  text-align: center;
  display: inline-block;
  font-size: 18px;
  font-weight: 900;
  text-transform: uppercase;
}

.button-offre {
  border: solid 2px var(--color_main);
  border-radius: 30px;
  color: #1758a0;
  font-weight: bold;
  padding: 15px 40px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.button-offre:hover {
  border: solid 2px var(--color_main);
  border-radius: 30px;
  color: #ed3561;
  font-weight: bold;
  padding: 15px 40px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.button-poleemploi {
  border: solid 2px #1758a0;
  border-radius: 5px;
  color: #1758a0;
  font-weight: bold;
  padding: 15px 40px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.button-poleemploi:hover {
  border: solid 2px #1758a0;
  background-color: #1758a0;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  padding: 15px 40px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.button-recherche {
  border: solid 2px #d83831;
  background-color: #fff;
  border-radius: 5px;
  color: #d83831;
  font-weight: bold;
  /* padding: 2px 10px; */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 0.9rem;
  height: 40px;
  width: 40px;
}

.button-recherche:hover {
  border: solid 2px #000;
  background-color: #fff;
  border-radius: 5px;
  color: #1758a0;
  font-weight: bold;
  /* padding: 2px 10px; */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 0.9rem;
}

.offres {
  padding: 10px 0 30px 0;
  border-bottom: solid 8px #ed3561;
}

.offres-liste {
  background-color: #ebebeb;
  padding-bottom: 25px;
}

.offres-liste .cadre {
  background-color: #fff;
  border: solid 2px var(--color_main);
  padding: 20px 10px 10px 10px;
  /* margin-top: 10px; */
  margin-bottom: 15px;
}

.title-offre {
  font-family: "Oswald", sans-serif;
  font-weight: 900;
  font-size: 32px;
  text-transform: uppercase;
  color: var(--color_main);
  line-height: 32px;
  /* margin-top: 30px; */
  /* margin-bottom: 25px; */
}

/* FOOTER */
.footer {
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
}

.footer .active {
  /* padding-bottom: 18px; */
  /* margin-top: 28px; */
  color: #ed3561;
  /* border-bottom: solid 10px #ed3561; */
  letter-spacing: 3px;
}

.footer a {
  text-decoration: none;
  color: var(--color_main);
  font-size: 1.1rem;
}

.page-footer {
  border-top: #ed3561 15px solid;
  background: #fff;
  padding: 28px 0px;
}

.page-footer .navigation-list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.page-footer .navigation-list .list-item {
  height: 78px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px;
  -webkit-transition: linear all 0.3s;
  -moz-transition: linear all 0.3s;
  -ms-transition: linear all 0.3s;
  -o-transition: linear all 0.3s;
  transition: linear all 0.3s;
  color: var(--turquoise_light);
  font-size: var(--font-size-footer-content);
}

.page-footer .navigation-list .list-item:hover {
  background: #e6234d;
}

.footer-title {
  color: var(--color_main);
  text-decoration: none;
  font-size: 0.9rem;
  font-style: italic;
  font-weight: 700;
}

.footer-title:hover {
  color: #e6234d;
  text-decoration: none;
  font-size: 0.85rem;
}

.modal {
  --bs-modal-width: 50% !important;
}

.logo-edition {
  position: absolute;
  top: 0;
  left: 2%;
}

.bigtitles-bloc {
  margin: 0 0 110px auto;
  position: relative;
  height: auto;
  width: 77%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.home-forum-description-bulle {
  position: absolute;
  width: 80%;
  height: auto;
  left: 50%;
  top: 58%;
  transform: translate(-50%, -50%);
  padding: 0;
  margin: 0;
  font-weight: bold;
  text-align: center;
  font-size: 1.1rem;
}

.div-bulle {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 220px;
  width: 241px;
  margin: 0;
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translate(-50%, 50%);
}

.bulle-emploi {
  position: absolute;
  top: calc(-20px - 3vw);
  right: 0;
  width: 200px;
  z-index: 1;
}

.bulle-offres {
  font-size: 1.3rem;
  font-weight: 700;
  margin-bottom: 35px;
  text-transform: uppercase;
  text-align: center;
  border-radius: 8px;
  padding: 9px 18px;
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
}

.bulle-emploi-img {
  position: absolute;
  top: -80px;
  right: -50px;
}

.save-the-date {
  position: relative;
  height: auto;
  width: auto;
}

.save-the-date span {
  margin: auto;
  position: relative;
  color: #ed3561;
  /*font-family: "Oswald", sans-serif;*/
  font-size: 1.5rem;
  font-weight: 900;
  line-height: 35px;
  text-transform: uppercase;
  height: auto;
  width: auto;
  padding: 2px 20px;
  display: block;
}

.save-the-date img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.div-date-home {
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0;
  position: absolute;
  right: 0;
  bottom: 0;
  color: #ed3561;
  font-family: "Oswald", sans-serif;
  font-size: 2.65rem;
  /* font-weight: bold; */
  line-height: 50px;
  text-transform: uppercase;
  transform: translate(-5%, 100%);
  height: auto;
  width: auto;
  padding: calc(30px + 0.55vw);
  background-position: center;
}

.div-hour-home {
  background-color: #e6234d;
  border-radius: 7px;
  text-align: center;
  margin: 0;
  position: absolute;
  left: -50px;
  top: -30px;
  color: #fff;
  font-family: "Oswald", sans-serif;
  font-size: 2rem;
  /* font-weight: bold; */
  line-height: 50px;
  text-transform: uppercase;
  height: auto;
  width: auto;
  padding: calc(20px + 0.55vw);
  box-shadow: 7px 7px 2px 1px #2a3a52;
}

.div-programme {
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0;
  position: absolute;
  right: 0;
  bottom: 0;
  color: #fff;
  /* font-family: "Oswald", sans-serif; */
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 30px;
  text-transform: uppercase;
  transform: translate(-5%, 100%);
  height: auto;
  width: auto;
  padding: calc(30px + 0.55vw);
  background-position: center;
}

.div-bgrectangleillu {
  position: absolute;
  z-index: 1;
  right: calc(300px + 40vw);
  top: 20px;
  object-fit: contain;
  width: auto;
  height: clamp(300px, 60vw, 800px);
}

.divbulle-programme {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  max-height: 400px;
  height: calc(200px + 42vw);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bulle-partenaires {
  position: absolute;
  top: calc(300px - 40vw);
  right: 0;
  top: 190px;
  margin-right: 50px;
  width: auto;
}

.bulle-partenaires-responsive {
  display: none;
}

.partenaires-titre-responsive {
  display: none;
}

.bulle-emploi-img-responsive {
  display: none;
}

.padding-offres {
  padding-bottom: 100px;
}

.searchbar {
  width: 640px !important;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto !important;
  position: relative;
}

.searchbar input {
  width: 100%;
  height: 100%;
  font-size: 0.9rem;
}

.searchbar .mb-3 {
  width: calc(100% - 40px);
  padding: 0 6px 0 0;
  height: 40px;
  margin: 0 !important;
}

@media screen and (max-width: 1500px) {
  /*
  .bulle-emploi-img {
    position: absolute;
    top: -60px;
    right: 2px;
  }
  */
}

@media screen and (max-width: 1199px) {
  .grossedivbubulle {
    margin: 28px 0 0 0 !important;
  }
  .logo-edition {
    left: -20px;
  }
  .title-hero-main {
    font-size: 4.8rem;
  }
  .title-hero-main-bold-left {
    font-size: clamp(3rem, 5.5vw, 5rem);
    margin: 0 14% 0.5rem 0;
  }
  .title-hero-main-bold-right {
    font-size: clamp(3rem, 5.5vw, 5rem);
  }
  .div-bulle {
    transform: translate(-50%, 50%);
  }
  .bulle-partenaires {
    display: none;
  }
  .bulle-partenaires-responsive {
    display: block;
    padding-top: 35px;
    text-align: center;
    font-size: 2rem;
    color: var(--color_main);
  }
  .partenaires-titre {
    font-size: 1.4rem;
    font-weight: 800;
    margin-bottom: 35px;
    text-transform: uppercase;
    text-align: center;
    padding: 25px 25px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    color: var(--color_main) !important;
  }

  .partenaires-titre-responsive {
    display: none;
  }

  /*
  .bulle-emploi-img {
    right: -60px;
  }
  */
}

@media screen and (max-width: 991px) {
  .padding-offres {
    padding-bottom: 10px;
  }

  .header {
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #fff;
  }

  .header a {
    text-decoration: none;
    color: var(--color_main);
    font-size: 1.05rem;
    margin-bottom: 4px;
  }

  .header .active {
    padding-bottom: 5px;
    margin-top: 0;
    color: #ed3561;
    border-bottom: solid 5px #ed3561;
    letter-spacing: 1px;
  }
  .card-title-programme {
    font-size: 17px !important;
    margin-bottom: 28px !important;
  }
  .bigtitles-bloc {
    margin: 0 0 0 auto;
    width: 100%;
  }
  .logo-edition {
    position: relative;
    left: unset;
    top: unset;
    margin: 0 auto;
    display: block;
  }
  .div-hour-home{
    width: 100%;
    position: relative;
    margin: auto;
    margin-bottom: 2.5rem;
    top: unset;
    left: unset;
  }
  .bigtitles-bloc {
    margin: 0 0 calc(150px + 10vw) auto;
  }
  .div-bulle {
    transform: translate(-50%, 100%);
    left: 10%;
  }
  .title-hero-forum {
    width: 100%;
    text-align: center !important;
  }
  .title-hero-main {
    width: 100%;
    text-align: center !important;
  }
  .title-hero-main-bold-left {
    width: 100%;
    text-align: center !important;
    font-size: clamp(2rem, 5.5vw, 3.5rem);
    margin: 0;
  }
  .title-hero-main-bold-right {
    width: 100%;
    font-size: clamp(2rem, 5.5vw, 3.5rem);
    text-align: center !important;
  }
  .datebutton {
    margin-left: auto;
    margin-right: auto;
  }
  .bulle-partenaires-responsive {
    display: block;
    text-align: center;
    font-size: 1.8rem;
    color: var(--color_main);
  }
  .partenaires-titre {
    font-size: 1.4rem;
    font-weight: 800;
    margin-bottom: 35px;
    text-transform: uppercase;
    text-align: center;
    padding: 25px 25px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    color: var(--color_main) !important;
  }

  .partenaires-titre-responsive {
    display: none;
  }

  .bulle-emploi-img {
    display: none;
  }

  .bulle-emploi-img-responsive {
    display: block;
    font-size: 1.5rem;
    background-color: #ed3561;
    color: #fff;
    text-align: center;
    padding: 5px 0px;
    text-transform: uppercase;
    margin: 25px 0px;
  }
}

@media screen and (max-width: 767px) {

  .bandeau {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 0.95rem;
  }

  .header {
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #fff;
  }

  .header a {
    text-decoration: none;
    color: var(--color_main);
    font-size: 1.05rem;
    margin-bottom: 4px;
  }

  .header .active {
    padding-bottom: 5px;
    margin-top: 0;
    color: #ed3561;
    border-bottom: solid 5px #ed3561;
    letter-spacing: 1px;
  }

  .title-hero-forum {
    font-size: 2.1rem;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    font-family: "Oswald", sans-serif;
    text-align: end;
  }

  .title-hero-main {
    font-size: 3.6rem;
    /* font-weight: bold; */
    color: #fff;
    text-transform: uppercase;
    font-family: "Oswald", sans-serif;
    letter-spacing: 7px;
    font-weight: 200;
    text-align: end;
  }

  .title-hero-main-bold-left {
    font-size: clamp(2.75rem, 5.5vw, 3.5rem);
    margin: 0;
  }

  .title-hero-main-bold-right {
    font-size: clamp(2.75rem, 5.5vw, 3.5rem);
    /* font-weight: bold; */
    color: #fff;
    text-transform: uppercase;
    font-family: "Oswald", sans-serif;
    font-weight: 900;
    text-align: end;
  }

  .div-date-home {
    font-size: 1.6rem;
    position: relative;
    right: unset;
    bottom: unset;
    margin: 0 auto;
    transform: unset;
  }

  .title-offre {
    font-family: "Oswald", sans-serif;
    font-weight: 900;
    font-size: 25px;
    text-transform: uppercase;
    color: var(--color_main);
    line-height: 32px;
    /* margin-top: 30px; */
    /* margin-bottom: 25px; */
  }

  .texte-offre {
    font-size: 0.8rem;
  }

  .footer {
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #fff;
  }

  .footer .active {
    /* padding-bottom: 18px; */
    /* margin-top: 28px; */
    color: #ed3561;
    /* border-bottom: solid 10px #ed3561; */
    letter-spacing: 3px;
  }

  .footer a {
    text-decoration: none;
    color: var(--color_main);
    font-size: 1.1rem;
  }

  .modal {
    --bs-modal-width: 80% !important;
  }

  .bulle-partenaires-responsive {
    display: block;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 900;
    color: var(--color_main);
  }

  .partenaires-titre {
    display: none;
  }

  .partenaires-titre-responsive {
    display: block;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 900;
    color: #fff;
  }

  .bulle-emploi-img-responsive {
    display: block;
    font-size: 1.3rem;
    background-color: #ed3561;
    color: #fff;
    text-align: center;
    padding: 5px 0px;
    text-transform: uppercase;
  }

  .container-bulle-responsive {
    height: 950px;
  }

  .div-bulle {
    transform: translate(-50%, 100%);
    left: 50%;
    bottom: 0;
    top: 275px;
  }
}
